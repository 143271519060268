import React, { useState } from "react";

// MUI
import TextField from "@mui/material/TextField";

// COMPONENTS
import { BlackButtonArrow } from "../../buttons/buttons";

export const SearchSku = ({ lang }) => {
  const [SKU, setSKU] = useState("");

  const handleChange = (event) => {
    setSKU(event.target.value);
  };

  //get the sku data
  async function getSKU() {
    // let notfound = true;
    let params = SKU;
    //remove all white spaces
    let querryString = params.replace(/ /g, "");
    querryString = querryString.replace(/\+/g, "%2B");
    querryString = querryString.replace(/ /g, "%2B");
    querryString = querryString.toUpperCase();

    await fetch(
      `https://wheelsapi.com/api/wheels?filters[sku][$eq]=${querryString}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.data.length !== 0) {
            document.location.href = "/wheel?sku=" + querryString;
            // notfound = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    await fetch(
      `https://www.wheelsapi.com/api/tires?filters[sku][$eq]=${querryString}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.data.length !== 0) {
            document.location.href = "/tire?sku=" + querryString;
            // notfound = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return (
    <>
      <TextField
        label="SKU"
        variant="standard"
        fullWidth={true}
        value={SKU}
        onChange={handleChange}
      />
      <BlackButtonArrow givenFunction={() => getSKU()}>
        {lang ? "Rechercher" : "Search"}
      </BlackButtonArrow>
    </>
  );
};
